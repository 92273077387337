<div>
  <h3 nz-typography>Events</h3>
  <nz-spin [nzSpinning]="preloadService.isLoad$ | async">
    <nz-tabset>
      <nz-tab [nzTitle]="i" *ngFor="let i of events.keys()">
        <div>
          <nz-table #basicTable [nzData]="events!.get(i) || []" [nzFrontPagination]=false>
            <thead>
            <tr>
              <th>Event</th>
              <th>Location</th>
              <th>Date</th>
              <th>Time</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let event of events!.get(i)">
              <td>
                <img *ngIf='event.isLive()' class='video-live-icon' src='/assets/images/live-streaming.webp' alt=''>
                {{ event.name }}
              </td>
              <td>{{ event.location?.name || '' }}</td>
              <td nz-tooltip [nzTooltipTitle]="event.location?.tz" nzTooltipPlacement="top">{{ event.fromToDateString }}</td>
              <td nz-tooltip [nzTooltipTitle]="event.location?.tz" nzTooltipPlacement="top">{{ event.fromToTimeString }}</td>
              <td class="event-btns">
                <button *ngIf="!event.isPPV" nzTooltipTitle="Manage ppv" nz-tooltip nz-button (click)='onManagePpvClick(event)'><i nz-icon nzType="dollar" nzTheme="outline"></i></button>
                <button *ngxPermissionsOnly='[permissions.EditArenaEvent]' nzTooltipTitle="Edit event" nz-tooltip nz-button (click)='onEditClick(event)'><i nz-icon nzType="edit" nzTheme="outline"></i></button>
                <button nzTooltipTitle="Download json" nz-tooltip nz-button (click)='onDownloadJsonClick(event)'><i nz-icon nzType="download" nzTheme="outline"></i></button>
                <button *ngIf='eventHasVideo(event) && eventVideoSrc(event)' nzTooltipTitle="Play video" nz-tooltip nz-button (click)='onPlayVideo(event)'><i nz-icon nzType="play-circle" nzTheme="outline"></i></button>
                <button *ngIf='event.isLive()' nzTooltipTitle="Manage chat" nz-tooltip nz-button (click)='onManageChat(event)'><i nz-icon nzType="wechat" nzTheme="outline"></i></button>
                <a *ngxPermissionsOnly='[permissions.StartStopEvent]' nz-dropdown [nzDropdownMenu]="menu">
                  <span nz-icon nzType="global" nzTheme="outline"  style='font-size: 1.2rem' [style]="event.isLive() ? { color: '#52c41a' } : { color: '#c00' }"></span>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu nzSelectable>
                    <li nz-menu-item [nzDisabled]='event.isLive()' (click)='onStartEventClick(event)'>Start</li>
                    <li nz-menu-item [nzDisabled]='!event.isLive()' (click)='onStopEventClick(event)'>Stop</li>
                  </ul>
                </nz-dropdown-menu>
              </td>
            </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-tab>
    </nz-tabset>
  </nz-spin>
</div>
