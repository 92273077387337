<nz-layout *ngIf='data'>
  <nz-header>
    <nz-row nzJustify="space-between">
      <nz-col>
        <span *ngIf="data?.location?.name" [innerText]="data.location.name.toUpperCase()" class="arena-name"></span>
      </nz-col>
      <nz-col>
        <span *ngIf="!data?.location?.link?.length" class="arena-name">No website</span>
        <a *ngIf="data?.location?.link" [href]="data?.location?.link" class="arena-name" target="_blank">
          {{data.location.link}}
        </a>
      </nz-col>
      <nz-col>
        <a [nzDropdownMenu]="menu" nz-dropdown>
          <nz-row nzAlign="middle">
            <span [innerText]="data?.location?.isPending ? 'Pending' : 'Active'" class="arena-name"></span>
            <div [ngClass]="data?.location?.isPending ? '' : 'isActive'" class="arena-active-indicator"></div>
          </nz-row>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu *ngIf="data?.location?.isPending">
            <li nz-menu-item (click)="activateArena()">Activate</li>
          </ul>
        </nz-dropdown-menu>
      </nz-col>
    </nz-row>
  </nz-header>
  <nz-content class="arena-content">
    <nz-spin [nzSpinning]="preloadService.isLoad$ | async">
      <nz-row [nzGutter]="[16, 8]">
        <nz-col [nzSpan]="8" [nzMd]="24" [nzXl]="6"><nz-card>
          <nz-row [nzGutter]="[16, 16]">
            <nz-col [nzMd]="12" [nzLg]="6" [nzXl]="24" class="arena-photo">
              <app-arena-photo [userId]='id'
                               [imageType]="imageTypes.ARENA_AVATAR"
                               [previewPlaceholder]="avatarPhoto?.href || ''">
              </app-arena-photo>

            </nz-col>
            <nz-col [nzMd]="12" [nzLg]="6" [nzXl]="24" class="arena-photo" *ngFor="let image of photos; let pos = index">
              <app-arena-photo [userId]='id'
                               [imageId]="image?.id"
                               [position]="pos"
                               [imageType]="image ? image.imageType : imageTypes.ARENA_PHOTO"
                               [previewPlaceholder]="image?.href || ''">
              </app-arena-photo>
            </nz-col>
          </nz-row>
        </nz-card></nz-col>
        <nz-col nzFlex="auto" [nzMd]="24" [nzXl]="16">
          <nz-row nzAlign="top" [nzGutter]="[16, 16]">
            <nz-col nzFlex="1" class="arena-content-stretch">
              <nz-card>
                <app-arena-contact [data]="data"></app-arena-contact>
              </nz-card>
            </nz-col>
            <nz-col nzFlex="1" class="arena-content-stretch">
              <nz-card>
                <app-arena-info *ngIf='data?.location' [data]="data.location" (openAddressSelection)="openModal()"></app-arena-info>
              </nz-card>
            </nz-col>
          </nz-row>
          <nz-row *ngxPermissionsOnly="[permissions.ViewArenaEvents]" [nzGutter]="[16, 16]">
            <nz-col nzFlex="auto">
              <nz-card>
                <app-arena-events *ngIf="data" [arena]="data" [id]="data.id"></app-arena-events>
              </nz-card>
            </nz-col>
          </nz-row>
        </nz-col>
      </nz-row>
    </nz-spin>
  </nz-content>
  <nz-footer *ngxPermissionsOnly="[permissions.EditArenaInfo]">
    <nz-row nzJustify="end">
      <nz-button-group>
        <button nz-button nzType="text" [disabled]="someValueHasBeenChanged" (click)="cancel()">CANCEL</button>
        <button nz-button nzType="primary" [disabled]="someValueHasBeenChanged" (click)="onUpdateClick()">SAVE</button>
      </nz-button-group>
    </nz-row>
  </nz-footer>
  <nz-modal [(nzVisible)]="isModalVisible" nzTitle="Select address" (nzOnCancel)="handleModalCancel()">
    <ng-container *nzModalContent>
      <app-address-selection (onSelect)="onAddressChange($event)"></app-address-selection>
    </ng-container>
    <div *nzModalFooter>
      <button nz-button nzType="default" (click)="handleModalCancel()">Cancel</button>
    </div>
  </nz-modal>
</nz-layout>
